@import '~antd/dist/antd.css';
@import '~antd/dist/antd.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

td.ant-table-cell{
  padding : 0px !important;
  vertical-align: top;
}

th.ant-table-cell{
  padding : 0px !important;
  background-color: #F0F0F0 !important;
}

.MuiIcon-fontSizeSmall{
  font-size: 5px;
}

.ant-drawer-body{
  padding : 15px 40px;
}

.ant-tabs-content{
  height : 100%;
  width : 100%;
}

.ant-layout-content {
  flex: auto;
  min-height: 0;
  width: 80%;
}

td.ant-table-cell > .ant-table-selection-column{
  padding : 1rem !important;
} 